import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import ClientsController from "../Controllers/ClientsController";
import Utils from "../Utils/Utils";
import { makeStyles, TextField, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getApps, getClients, getProviders, getServices, getStatuses, getAuth } from "../cache/selectors";
import ControlledOpenSelect from "../components/DropdownSelect/ControlledOpenSelect";



const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
}));

const mapStateToProps = state => {
    const apps = getApps(state);
    const clients = getClients(state);
    const providers = getProviders(state);
    const services = getServices(state);
    const statuses = getStatuses(state);
    const auth = getAuth(state);
    return { apps, clients, providers, services, statuses, auth };
};

class APIManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'API Management',
            apps: props.apps,
            clients: props.clients,
            isLoading: false,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', readonly: true, export: false, editable: 'never' },
                {
                    title: 'Client',
                    field: 'client',
                    required: true,
                    lookup: {},
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => props.onChange(e)}
                            value={props.value}
                            lookup={Utils.sortAlphabetically(this.state.clients)}
                        />
                    ),
                    // defaultGroupOrder: 0
                },
                {
                    title: 'API Key',
                    field: 'key',
                    type: 'string',
                    // editable: 'onUpdate',
                    // emptyValue: Utils.getUUID(),
                    // initialEditValue:  Utils.getUUID(),
                    // cellStyle: {
                    //     width: '300px'
                    // },
                    editComponent: props => (
                        <TextField
                            id="key"
                            fullWidth
                            // variant="outlined"
                            value={props.value}
                            onChange={e => props.onChange(e.target.value)}
                        />
                    )
                }, {
                    title: 'Google API Key',
                    field: 'google_api_key',
                    cellStyle: {
                        minWidth: '200px'
                    },
                },
                {
                    title: 'App',
                    field: 'app',
                    hint: 'App Name',
                    required: true,
                    lookup: {},
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => props.onChange(e)}
                            value={props.value}
                            lookup={this.state.apps}
                        />
                    ),
                },
                {
                    title: 'Line Of Sight API Key',
                    field: 'los_api_key',
                    cellStyle: {
                        minWidth: '200px'
                    },
                },
                { title: 'Active', field: 'active', type: 'boolean', emptyValue: false },
                { title: 'Created At', field: 'created_at', type: 'date', editable: 'never', emptyValue: Utils.getTodayTimestamp() },
                { title: 'Expiration Date', field: 'expiration_date', type: 'date' },
                { title: 'Description', field: 'description' },
                { title: 'Max Concurrent Bulk Jobs', field: 'max_jobs', type:'number', initialEditValue: 1 },
                { title: 'Max Concurrent Bulk Locations', field: 'max_locations', type:'number', initialEditValue: 20 },
                { title: 'Comment', field: 'comment' },
            ],
            data: [],
        };
    }

    loading = loading => {
        this.setState({isLoading: loading})
    }

    componentDidMount() {
        // this.initialiseData();
        this.initialiseColumnData();
        this.initialiseData();
    }

    onRefresh = () => {
        this.initialiseColumnData();
        this.initialiseData();
    }


    validation = (newData) => {
        return new Promise((resolve, reject) => {
            let data = JSON.parse(JSON.stringify(newData));
            console.log('data',data)
            if (!data) {
                reject('No data provided');
                return;
            }
            delete data.client_id
            if (!data.client) {
                return reject('Invalid property: Client');
            } else {
                data.client_id = +data.client
            }
            if (!data.key) {
                return reject('Invalid property: API Key');
            }
            delete data.app_id
            if (!data.app) {
                return reject('Invalid property: App');
            } else {
                data.app_id = +data.app
            }

            return resolve(data);
        });
    }

    handleRowAdd = (newData) => {
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if (!newData.created_at) newData.created_at = Utils.getCurrentDate('-');

            ClientsController.AddAPIKey(newData)
                .then(response => {
                    console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    if(self.props.setProviders) self.props.setProviders(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };
    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            ClientsController.UpdateAPIKey(oldData, newData)
                .then(response => {
                    console.log(response)
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    if(self.props.setProviders) self.props.setProviders(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            ClientsController.DeleteAPIKey(oldData.id)
                .then(response => {
                    console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    if(self.props.setProviders) self.props.setProviders(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };


    handleError = (error) => {
        this.loading(false)
        alert(error)
    };
    handleRowClick = (client) => {
        console.log(client)
    };

    initialiseColumnData = () => {
        let columns = this.state.columns;
        columns[1].lookup = this.state.clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        columns[4].lookup = this.state.apps.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        this.setState({ columns: columns });
    };

    initialiseData = () => {
        this.loading(true)
        ClientsController.GetAPIKeys()
            .then(results => {
                let parents = {};
                results.forEach(row => {
                    if (this.state.clients && this.state.clients.length > 0) {
                        let x = this.state.clients.filter(e => { return e.full_name === row.client })
                        if (x && x.length > 0) row.client = x[0].id
                    }
                    if (this.state.apps && this.state.apps.length > 0) {
                        let x = this.state.apps.filter(e => { return e.full_name === row.app })
                        if (x && x.length > 0) row.app = x[0].id
                    }

                    if(!parents[row.client]) parents[row.client] = row.id;
                    else row.parentId = parents[row.client]
                });
                this.setState({data: results});
                this.loading(false);
            })
            .catch(err => console.error(err));
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={this.state.title}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    handleError={this.handleError}
                    isEditable={this.props.auth.edit_authorized}
                    isDeletable={this.props.auth.edit_authorized}
                    validation={this.validation}
                    onRefresh={this.onRefresh}

                />
            </div>
        );
    }
}

// APIManagement.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default withStyles(useStyles)(connect(mapStateToProps)(APIManagement));
export default connect(mapStateToProps)(APIManagement);
