import React, { useState, useEffect } from 'react'
import { Dialog } from "@material-ui/core"
import Utils from '../../Utils/Utils'

import Alert from '../Alert/Alert'

import AddSubscriptions from './DialogComponents/AddSubscriptions'
import ViewSubscriptions from './DialogComponents/ViewSubscriptions'

import { connect } from "react-redux";
import { getClients, getProviders, getServices, getStatuses, getApps } from "../../cache/selectors";

const mapStateToProps = state => {
	const apps = getApps(state);
  const clients = getClients(state);
  const providers = getProviders(state);
  const services = getServices(state);
  const statuses = getStatuses(state);
  return { apps, clients, providers, services, statuses };
};

function BulkSubscriptionDialog({
	open, 
	validation, 
	handleRowAdd, 
	handleClose,
	onRefresh,
	clients = [], 
	keys = [], 
	services = [], 
	statuses = [], 
	providers = [],
	coverageData = [],
	apps=[],
	clientFilter=''
}) {

	const [isLoading, setIsLoading] = useState(false)

	const [dialog, setDialog] = useState('add')

	const [currentClient, setCurrentClient] = useState(clientFilter)

	const [alertState, setAlertState] = useState({
		message: '',
		state: '',
		open: false
	})

	const columns = [
		{
			title: 'Client',
			field: 'client',
			required: true,
			lookup: Utils.sortAlphabetically(clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {})),
			editable: 'never'
		},
		{
			title: 'API Key',
			field: 'apikey',
			required: true,
			lookup: Utils.sortAlphabetically(keys.reduce((acc, cur, i) => { acc[cur.id] = cur.description; return acc; }, {}))
		},
		{
			title: 'Service',
			field: 'service',
			required: true,
			lookup: Utils.sortAlphabetically(services.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))
		},
		{
			title: 'Provider',
			field: 'provider',
			required: true,
			lookup: Utils.sortAlphabetically(providers.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))
		},
		{
			title: 'Status',
			field: 'status',
			required: true,
			lookup: Utils.sortAlphabetically(statuses.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))
		},
		{ title: 'Max Distance', field: 'max_distance', type: 'numeric' },
		{ title: 'Use External API', field: 'use_external_api', type: 'boolean' },
		{ title: 'External API Timeout (ms)', field: 'external_api_timeout', type: 'numeric' },
		{ title: 'External API Details', field: 'external_api_details', cellStyle: {minWidth: '200px'}}
	]

	useEffect(()=>{
		setCurrentClient(clientFilter)
	},[clientFilter])

	const [subscriptions, setSubscriptions] = useState([])

	const handleSubmit = async () => {
		setIsLoading(true)

		try {
			const promises = subscriptions.map((row) => {
				if(handleRowAdd) {
					return handleRowAdd(row)
				}

				return Promise.resolve();
			})

			await Promise.all(promises)

			setIsLoading(false)
			
			handleEvent('Added subscription(s) successfully', 'success')

			if(!clientFilter || clientFilter === -1) { 
				setCurrentClient('')
			}

			if(onRefresh) {
				onRefresh()
			}

			onClose()

		} catch (err) {
			handleEvent(err?.message || err, 'error')
		}
	}

	const handleAddSubscriptions = async (rowData=[]) => {
		const newSubscriptions = []

		try {
			const validationPromises = rowData.map((row) => {
				if(Utils.isEmpty(row)) {
					throw new Error("Invalid: All fields are empty.")
				}

				if (validation) {
					return validation(row)
				}

				return Promise.resolve();
			})

			const results = await Promise.all(validationPromises)

			results.forEach(result => {
				if (result) {
					newSubscriptions.push(result)
				}
			})

			setSubscriptions(newSubscriptions)
			setDialog('view')

		} catch (err) {
			handleEvent(err?.message || err, 'error')
		}
	}

	const onClose = () => {

		setIsLoading(false)
		setDialog('add')
		setSubscriptions([])

		if(!clientFilter || clientFilter === -1) setCurrentClient('')

		if (handleClose) handleClose()
	}

	const handleEvent = (message='', state='') => {
		setAlertState({message, state, open: true})
	}

	const handleCloseAlert = () => {
		setAlertState({message: '', state: '', open: false})
	}
  
	// aware that I can handle which content displays better but this manner works correctly and was simple to implement
	return (
		<>
			<Alert {...alertState} handleClose={handleCloseAlert}/>
			<Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} maxWidth='xl' fullWidth>
				{dialog === 'add' && (
					<AddSubscriptions 
						columns={columns} 
						handleEvent={handleEvent} 
						handleSubmit={handleAddSubscriptions}
						onClose={onClose}
						validation={validation}
						clients={clients}
						coverageData={coverageData}
						keys={keys}
						providers={providers}
						services={services}
						statuses={statuses}
						currentClient={currentClient}
						setCurrentClient={setCurrentClient}
						subscriptions={subscriptions}
						apps={apps}
					/>
				)}
				{dialog==='view' && (
					<ViewSubscriptions 
						subscriptions={subscriptions} 
						columns={columns} 
						handleSubmit={handleSubmit} 
						setDialog={setDialog} 
						onClose={onClose} 
						client={clients.find((item) => item.id.toString() === currentClient.toString())?.full_name}
						isLoading={isLoading}
					/>
				)}
			</Dialog>
		</>
	)
}

export default connect(mapStateToProps)(BulkSubscriptionDialog)