import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import ClientsController from "../Controllers/ClientsController";
import DataController from "../Controllers/DataController";
import Flatten from "../Utils/Flatten";
import Utils from "../Utils/Utils";
import { makeStyles, TextField, useTheme, Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getApps, getClients, getProviders, getServices, getStatuses, getAuth } from "../cache/selectors";
import ControlledOpenSelect from "../components/DropdownSelect/ControlledOpenSelect";
import ControlledOpenSelectManagedState from "../components/DropdownSelect/ControlledOpenSelectManagedState";
import { ThreeSixty, LibraryAdd, Close, DeleteSweep, Cancel } from "@material-ui/icons";
import BulkSubscriptionDialog from '../components/Dialogs/BulkSubscriptionDialog';
import BulkDeleteDialog from "../components/Dialogs/BulkDeleteDialog";
import { MTableToolbar  } from 'material-table'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    }
}));

const mapStateToProps = state => {
    const apps = getApps(state);
    const clients = getClients(state);
    const providers = getProviders(state);
    const services = getServices(state);
    const statuses = getStatuses(state);
    const auth = getAuth(state);
    return { apps, clients, providers, services, statuses, auth };
};

class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Subscriptions',
            clients: props.clients,
            api_keys: [],
            availableKeys: [],
            filter: props.location.state ? props.location.state.client : -1,
            providers: props.providers,
            services: props.services,
            statuses: props.statuses,
            isLoading: false,
            isEditable: this.props.auth.edit_authorized,
            isDeletable: this.props.auth.edit_authorized,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', readonly: true, export: false, editable: 'never' },
                // { title: 'Client', field: 'client', required: true, lookup: {}, defaultGroupOrder: 0 },
                {
                    title: 'Client',
                    field: 'client',
                    required: true,
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => {
                                this.setClient(e);
                                props.onChange(e);
                            }}
                            value={props.value}
                            lookup={Utils.sortAlphabetically((this.state.clientLookup))}
                        />
                    ),
                    lookup: {}
                },
                {
                    title: 'API Key',
                    field: 'apikey',
                    required: true,
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => props.onChange(e)}
                            value={props.value}
                            lookup={Utils.sortAlphabetically(this.state.availableKeys)}
                        />
                    ),
                    lookup: {}
                },
                {
                    title: 'Service',
                    field: 'service',
                    required: true,
                    lookup: {},
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => props.onChange(e)}
                            value={props.value}
                            lookup={Utils.sortAlphabetically(this.props.services)}
                        />
                    ),
                },
                {
                    title: 'Provider',
                    field: 'provider',
                    required: true,
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => props.onChange(e)}
                            value={props.value}
                            lookup={Utils.sortAlphabetically(this.props.providers)}
                        />
                    ),
                    lookup: {},
                },
                {
                    title: 'Status',
                    field: 'status',
                    required: true,
                    lookup: {},
                    // editComponent: props => (
                    //     <ControlledOpenSelect
                    //         onChange={e => props.onChange(e)}
                    //         value={props.value}
                    //         lookup={Utils.sortAlphabetically(this.props.statuses)}
                    //     />
                    // ),
                },
                { title: 'Max Distance', field: 'max_distance', type: 'numeric' },
                { title: 'Use External API', field: 'use_external_api', type: 'boolean' },
                // { title: 'External API Details', field: 'external_api_details' },
                { title: 'External API Timeout (ms)', field: 'external_api_timeout', type: 'numeric' },
                {
                    title: 'External API Details',
                    field: 'external_api_details',
                    cellStyle: {
                        minWidth: '400px'
                    },
                    initialEditValue: '{}',
                    editComponent: props => (
                        <TextField
                            id="external_api_details"
                            fullWidth
                            multiline
                            rows={10}
                            variant="outlined"
                            value={props.value}
                            onChange={e => props.onChange(e.target.value)}
                        />
                    )
                },
                { title: 'Data Available', field: 'data_available', type: 'boolean', readonly: true, editable: 'never' },
                { title: 'Has External API', field: 'external_request', type: 'boolean', readonly: true, editable: 'never' },
            ],
            data: [],
            selectedRows: [],
            activeDialog: '',
            actions: this.props.auth.edit_authorized === true && [
                this.actionsDict.bulkAdd
            ],
            options: {
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRows.some((selectedRow) => selectedRow.id === rowData.id)) ? '#bcdffb' : '#FFF'
                })
            }
        };
        // if (this.props.location.state && this.props.location.state.client)
        // this.setState({ filter: this.props.location.state.client })
    }

    editing = () => this.state.data.some((row) => {
        if(row?.tableData?.editing) {
            return true
        }
    })

    actionsDict = {
        bulkAdd: {
            icon: LibraryAdd,
            tooltip: 'Bulk Add',
            isFreeAction: true,
            onClick: (event) => {

                if(this.editing()) {
                    if(this.handleError) {
                        this.handleError('Please finish editing')
                    }

                    return
                }

                this.setState({activeDialog: 'bulk-add'})
            }
        },
        bulkDelete: {
            icon: DeleteSweep,
            tooltip: 'Bulk Delete',
            isFreeAction: true,
            onClick: (event) => this.setState({activeDialog: 'bulk-delete'})
        },
        clearSelections : {
            icon: Cancel,
            tooltip: 'Clear selections',
            isFreeAction: true,
            onClick: () => {
                this.setState({selectedRows: []}, () => {
                    this.onRefresh()

                    // remove bulk action
                    this.handleRowClick({})
                })
            }
        },
    }


    setClient = client => {
        let keys = []
        console.log("setting client: " + client)
        console.log(this.state.keyData);
        if (this.state.keyData) {
            let tmp = this.state.keyData.filter(item => { return item.client === (+client) });
            keys = tmp.reduce((acc, cur, i) => {
                if (cur.description) acc[cur.id] = cur.description
                else {
                    let tmp = this.props.apps.filter(item => { return item.id === (+cur.app) });
                    acc[cur.id] = cur.key + ` (${tmp[0].full_name})`;
                }
                return acc;
            }, {});
            console.log("keys")
            console.log(keys)
            this.setState({ availableKeys: keys });
        }
    }

    loading = loading => {
        this.setState({ isLoading: loading })
    }
    
    componentDidMount() {
        // this.initialiseData();
        // this.initialiseColumnData();
        this.initialiseData();
    }

    onRefresh = () => {
        this.initialiseData();
    }

    validation = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            let data = JSON.parse(JSON.stringify(newData));
            if (!data) {
                reject('No data provided');
                return;
            }
            delete data.coverage_id;
            if ((data.service && data.provider) && this.state.coverageData) {
                this.state.coverageData.forEach(row => {
                    if (+row.service === +data.service && +row.provider === +data.provider)
                        data.coverage_id = row.id;
                })
            }
            delete data.client_id
            if (data.client && this.props.clients) {
                data.client_id = data.client;
            }
            delete data.apikey_id
            if (data.apikey && this.state.keyData) {
                data.apikey_id = data.apikey;
            }
            delete data.status_id
            if (data.status && this.state.keyData) {
                data.status_id = data.status;
            }
            if (data.max_distance) {
                data.max_distance = +data.max_distance;
            } else
                delete data.max_distance

            if (!data.client_id) {
                return reject('Invalid property: client_id');
            }
            if (!data.coverage_id) {
                return reject('Invalid Service Provider');
            }
            if (!data.status_id) {
                return reject('Invalid property: Status');
            }
            if (!data.apikey_id) {
                return reject('Invalid property: API Key');
            }

            if(data.external_api_details) {
                try {
                    JSON.parse(data.external_api_details)
                } catch (e) {
                    return reject('Invalid JSON: External API Details')
                }
            }

            const compareStringArray = this.state.data.map(e => `${e.client}${e.service}${e.provider}${e.status}`)

            // edit mode
            if (oldData) {

                const newDataCompareString = `${data.client}${data.service}${data.provider}${data.status}`

                const oldDataCompareString = `${oldData.client}${oldData.service}${oldData.provider}${oldData.status}`

                if(newDataCompareString === oldDataCompareString) {
                    resolve(data)
                } else if (compareStringArray.includes(newDataCompareString)) {
                    return reject('Invalid Properties: Duplicate Subscription')
                }
            } else {
                const compareString = `${data.client}${data.service}${data.provider}${data.status}`
    
                // checking for duplicates
                if(compareStringArray.includes(compareString)) {
                    return reject('Invalid Properties: Duplicate Subscription')
                }
            }

            return resolve(data);
        });
    }

    handleRowAdd = (newData) => {

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if (!newData.created_at) newData.created_at = Utils.getCurrentDate();

            ClientsController.AddSubscription(newData)
                .then(response => {
                    console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };
    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            ClientsController.UpdateSubscription(oldData, newData)
                .then(response => {
                    console.log(response)
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            ClientsController.DeleteSubscription(oldData.id)
                .then(response => {
                    console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };

    handleError = (error) => {
        this.loading(false)
        alert(error)
    };

    handleRowClick = (selectedRow) => {

        if(this.state.filter === -1 ) return
        
        // determine if editing then stop selections 
		if(this.editing()) return

        const newSelectedRows = this.state.selectedRows

        // adjust selected rows
        if(!this.state.selectedRows.some((currentRow) => selectedRow?.id === currentRow.id) && selectedRow.hasOwnProperty('id')) {
            newSelectedRows.push(selectedRow)
            this.setState({selectedRows: newSelectedRows})
        } else {            
            const index = newSelectedRows.findIndex((row) => row.id === selectedRow.id)
            newSelectedRows.splice(index, 1)
            this.setState({selectedRows: newSelectedRows})
        }

        const actions = this.state.actions

        if(newSelectedRows.length) {

            const newActions = []

            if(this.props.auth.edit_authorized) {
                newActions.push(
                    this.actionsDict.bulkDelete
                )

                newActions.push(
                    this.actionsDict.clearSelections
                )
            }

            this.setState({
                actions: newActions,
                isEditable: false,
                isDeletable: false,
            })
        } else {

            const newActions = []

            if(this.props.auth.edit_authorized) {
                newActions.push(
                    this.actionsDict.bulkAdd
                )
            }

            this.setState({
                actions: newActions, 
                isEditable: this.props.auth.edit_authorized,
                isDeletable: this.props.auth.edit_authorized,
            })
        }
    };

    initialiseColumnData = () => {
        let columns = this.state.columns;
        console.log(this.props.services)
        let clientLookup = []
        let keyLookup = []
        if (this.props.clients) columns[1].lookup = Utils.sortObjectAlphabetically((this.props.clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {})));
        if (this.props.clients) clientLookup = Utils.sortObjectAlphabetically(this.props.clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))
        if (this.state.keyData) {
            columns[2].lookup = Utils.sortObjectAlphabetically(this.state.keyData.reduce((acc, cur, i) => { acc[cur.id] = cur.description; return acc; }, {}));
            keyLookup = Utils.sortObjectAlphabetically(this.state.keyData.reduce((acc, cur, i) => { acc[cur.id] = cur.description; return acc; }, {}));
        }
        if (this.props.services) columns[3].lookup = Utils.sortObjectAlphabetically(this.props.services.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}));
        if (this.props.providers) columns[4].lookup = Utils.sortObjectAlphabetically(this.props.providers.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}));
        if (this.props.statuses) columns[5].lookup = Utils.sortObjectAlphabetically(this.props.statuses.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}));
        this.setState({ columns: columns, clientLookup: clientLookup, availableKeys: keyLookup });
        // this.getSubscriptions();
    };

    initialiseData = () => {
        this.loading(true)
        if (this.state.filter < 0) {
            Promise.all([
                ClientsController.GetSubscriptions(),
                ClientsController.GetAPIKeys(),
                DataController.GetServiceCoverage()
            ]).then(results => {
                this.handleResults(results)
            });
        } else {
            Promise.all([
                ClientsController.GetClientSubscriptions(this.state.filter),
                ClientsController.GetAPIKeys(),
                DataController.GetServiceCoverage()
            ]).then(results => {
                this.handleResults(results)
            });
        }
    };

    handleResults = results => {
        let data = [], keyData = [], coverageData = []
        if (!results[0].success && !results[0].code) {
            let parents = {};
            results[0].forEach(row => {
                if (this.props.clients && this.props.clients.length > 0) {
                    let x = this.props.clients.filter(e => { return e.full_name === row.client })
                    if (x && x.length > 0) row.client = x[0].id
                }
                if (this.props.providers && this.props.providers.length > 0) {
                    let x = this.props.providers.filter(e => { return e.name === row.provider })
                    if (x && x.length > 0) row.provider = x[0].id
                }
                if (this.props.services && this.props.services.length > 0) {
                    let x = this.props.services.filter(e => { return e.name === row.service })
                    if (x && x.length > 0) row.service = x[0].id
                }
                if (this.props.statuses && this.props.statuses.length > 0) {
                    let x = this.props.statuses.filter(e => { return e.name === row.status })
                    if (x && x.length > 0) row.status = x[0].id
                }
                if (!parents[row.client]) parents[row.client] = row.id;
                else row.parentId = parents[row.client]
            });
            data = results[0]
        }
        if (!results[1].success && !results[1].code) {
            let parents = {};
            results[1].forEach(row => {
                if (this.props.clients && this.props.clients.length > 0) {
                    let x = this.props.clients.filter(e => { return e.full_name === row.client })
                    if (x && x.length > 0) row.client = x[0].id
                }
                if (this.props.apps && this.props.apps.length > 0) {
                    let x = this.props.apps.filter(e => { return e.full_name === row.app })
                    if (x && x.length > 0) row.app = x[0].id
                }

                if (!parents[row.client]) parents[row.client] = row.id;
                else row.parentId = parents[row.client]
            });
            keyData = results[1]
        }

        if (!results[2].success && !results[2].code) {
            let parents = {};
            results[2].forEach(row => {
                if (this.props.providers && this.props.providers.length > 0) {
                    let x = this.props.providers.filter(e => { return e.name === row.provider })
                    if (x && x.length > 0) row.provider = x[0].id
                }
                if (this.props.services && this.props.services.length > 0) {
                    let x = this.props.services.filter(e => { return e.name === row.service })
                    if (x && x.length > 0) row.service = x[0].id
                }
            });
            coverageData = results[2]
        }

        this.setState({ data: data, keyData: keyData, coverageData: coverageData });
        this.loading(false);
        this.initialiseColumnData();
    }

    handleDialogClose = () => {
        this.setState({activeDialog: ''})
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={(
                        <Grid container direction='row' spacing={1} style={{width: '400px'}} justifyContent='space-between'>  
                            <Grid item alignContent='center'>
                                {this.state.title}
                            </Grid>
                            <Grid xs item>
                                <ControlledOpenSelectManagedState 
                                    label='Client'
                                    lookup={Utils.sortAlphabetically((this.state.clientLookup))}
                                    value={this.state.filter}
                                    onChange={(e) => {
                                        if(this.editing()) {
                                            if(this.handleError) {
                                                this.handleError('Please finish editing')
                                            }

                                            return
                                        }

                                        this.setState({filter: e, selectedRows: []}, () => {
                                            this.onRefresh()

                                            // remove bulk action
                                            this.handleRowClick({})
                                        })
                                    }}
                                    endAdornment={(
                                        <IconButton 
                                            size='small' 
                                            onClick={() => {

                                                if(this.editing()) {
                                                    if(this.handleError) {
                                                        this.handleError('Please finish editing')
                                                    }

                                                    return
                                                }

                                                const currentFilter = this.state.filter
                                                this.setState({filter: -1, selectedRows: []}, () => {

                                                    if(currentFilter !== -1) {
                                                        this.onRefresh()

                                                        const newActions = []

                                                        if(this.props.auth.edit_authorized) {
                                                            newActions.push(
                                                                this.actionsDict.bulkAdd
                                                            )
                                                        }
                                            
                                                        this.setState({
                                                            actions: newActions, 
                                                            isEditable: this.props.auth.edit_authorized,
                                                            isDeletable: this.props.auth.edit_authorized,
                                                        })                                                        
                                                    }
                                                })
                                            }} 
                                            aria-label="Clear Filter" 
                                            disabled={this.state.filter > -1 ? false : true}
                                        >
                                            <Close  fontSize="inherit"/>
                                        </IconButton>
                                    )}
                                    inputProps={{ IconComponent: () => null}}
                                />
                            </Grid>
                        </Grid>
                    )}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    validation={this.validation}
                    handleRowClick={this.handleRowClick}
                    handleError={this.handleError}
                    isEditable={this.state.isEditable}
                    isDeletable={this.state.isDeletable}
                    onRefresh={this.onRefresh}
                    actions={this.state.actions}
                    options={this.state.options}
                />
                <BulkSubscriptionDialog 
                    open={this.state.activeDialog === 'bulk-add'} 
                    handleRowAdd={this.handleRowAdd} 
                    handleError={this.handleError} 
                    handleClose={this.handleDialogClose} 
                    validation={this.validation} 
                    onRefresh={this.onRefresh}
                    keys={this.state.keyData}
                    coverageData={this.state.coverageData}
                    clientFilter={this.state.filter}
                />
                <BulkDeleteDialog 
                    open={this.state.activeDialog === 'bulk-delete'}
                    handleRowRemove={this.handleRowRemove} 
                    handleError={this.handleError}
                    onClose={this.handleDialogClose}
                    onConfirm={() => {
                        this.setState({selectedRows: []})
                        
                        // remove bulk action
                        this.handleRowClick({})
                        this.onRefresh()
                    }}
                    selectedRows={this.state.selectedRows}
                    clientName={this.state.clients.find((item) => `${item.id}` === this.state.filter)?.full_name}
                    keys={this.state.keyData}
                />
            </div>
        );
    }
}

// Subscriptions.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default withStyles(useStyles)(connect(mapStateToProps)(Subscriptions));
export default connect(mapStateToProps)(Subscriptions);
