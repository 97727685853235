import React from 'react'
import {Snackbar, SnackbarContent, IconButton} from '@material-ui/core'
import {Close} from '@material-ui/icons'

const snackbarStateColors = {
    success: '#4caf50',
    info: '#0288d1',
    warn: '#f57c00',
    error: '#d32f2f'
}

function Alert({open=false, message='', state='', handleClose}) {

  return (
    <Snackbar
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
    >
        <SnackbarContent 
            style={{
                backgroundColor:snackbarStateColors[state],
            }}
            message={message}
            action={(
                <IconButton size="small" color="inherit" onClick={handleClose}>
                    <Close fontSize='small'/>
                </IconButton>
            )}
        />
    </Snackbar>
  )
}

export default Alert