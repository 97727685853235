import React, {useState} from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent, Grid, Button } from "@material-ui/core";
import ViewTable from '../Tables/ViewTable'
import Alert from '../Alert/Alert'
import DeleteSubscriptionsConfirmation from './DialogComponents/DeleteSubscriptionsConfirmation';
import Utils from '../../Utils/Utils'
import { connect } from "react-redux";
import { getClients, getProviders, getServices, getStatuses } from "../../cache/selectors";

const mapStateToProps = state => {
  const clients = getClients(state);
  const providers = getProviders(state);
  const services = getServices(state);
  const statuses = getStatuses(state);
  return { clients, providers, services, statuses };
};

function BulkDeleteDialog({selectedRows, onClose, onConfirm, open, handleRowRemove, clientName, clients = [], keys = [], services = [], statuses = [], providers = [],}) {

  const [isLoading, setIsLoading] = useState(false)

  const [deleteDialogConfirmationOpen, setDeleteDialogConfirmationOpen] = useState(false)

  const [alertState, setAlertState] = useState({
    message: '',
    state: '',
    open: false
  })

  const columns = [
		{
			title: 'Client',
			field: 'client',
			required: true,
			lookup: Utils.sortAlphabetically(clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {})),
			editable: 'never'
		},
		{
			title: 'API Key',
			field: 'apikey',
			required: true,
			lookup: Utils.sortAlphabetically(keys.reduce((acc, cur, i) => { acc[cur.id] = cur.description; return acc; }, {}))
		},
		{
			title: 'Service',
			field: 'service',
			required: true,
			lookup: Utils.sortAlphabetically(services.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))
		},
		{
			title: 'Provider',
			field: 'provider',
			required: true,
			lookup: Utils.sortAlphabetically(providers.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))
		},
		{
			title: 'Status',
			field: 'status',
			required: true,
			lookup: Utils.sortAlphabetically(statuses.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))
		},
		{ title: 'Max Distance', field: 'max_distance', type: 'numeric' },
		{ title: 'Use External API', field: 'use_external_api', type: 'boolean' },
		{ title: 'External API Timeout (ms)', field: 'external_api_timeout', type: 'numeric' },
		{ title: 'External API Details', field: 'external_api_details', cellStyle: {minWidth: '200px'}}
	]

  const handleConfirmChanges = async () => {
    const promises = []
    setIsLoading(true)
    closeDeleteDialog()

    try {

      const promises = selectedRows.map((row) => {
        if(handleRowRemove) {
          return handleRowRemove(row)
        }

        return Promise.resolve()
      })

      const results = await Promise.all(promises)

			setIsLoading(false)

      handleEvent('Deleted subscription(s) successfully', 'success')

      onConfirm()
			onClose()

    } catch (err) {
      handleEvent(err?.message || err, 'error')
    }
  }

  const handleEvent = (message, state) => {
    setAlertState({message, state, open: true})
  }

  const handleAlertClose = () => {
    setAlertState({message: '', state: '', open: false})
  }

  const openDeleteDialog = () => {
    setDeleteDialogConfirmationOpen(true)
  }

  const closeDeleteDialog = () => {
    setDeleteDialogConfirmationOpen(false)
  }

  return (
    <>
      <Alert {...alertState} handleClose={handleAlertClose}/>
      <Dialog onClose={onClose} open={open} maxWidth='xl' fullWidth>
        <DialogTitle>
          Confirm Deletion of selected subscription(s)
        </DialogTitle>
        <DialogContent>
          <ViewTable 
            isLoading={isLoading}
            title={`Delete Selected Subscription(s)`} 
            columns={columns} 
            data={selectedRows} 
            exportButton={false}
            pageSize={10}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button size='medium' color='primary' variant='contained' onClick={openDeleteDialog}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteSubscriptionsConfirmation 
        clientName={clientName}
        onClose={closeDeleteDialog}
        onConfirm={handleConfirmChanges}
        open={deleteDialogConfirmationOpen}
        rowLength={selectedRows.length}
      />
    </>
  )
}

export default connect(mapStateToProps)(BulkDeleteDialog)