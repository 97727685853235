import React from 'react'

import { Dialog, DialogTitle, DialogActions, DialogContent, Grid, Button } from "@material-ui/core";
import useTheme from '@material-ui/core/styles/useTheme'


function DeleteSubscriptionsConfirmation({rowLength, onConfirm, onClose, clientName, open}) {

  const theme = useTheme();
  
  return (
    <Dialog open={open} size='sm' fullWidth onClose={onClose}>
      <DialogTitle>
        Confirm deletion of subscriptions from {clientName}
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete {rowLength} subscription(s) from {clientName}?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm} style={{color: 'red'}}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteSubscriptionsConfirmation