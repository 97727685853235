import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, makeStyles, Button } from '@material-ui/core';
import Utils from '../../Utils/Utils';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  },
}));

export default function ControlledOpenSelect({ ...props }) {
  const classes = useStyles();
  const [key, setKey] = React.useState(props.value ? props.value : '');
  const [open, setOpen] = React.useState(false);

  const handleChange = event => {
    setKey(event.target.value);
    if (props.onChange) props.onChange(event.target.value)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSortedObj = (obj) => {
    let newOBJ = {}
    if (typeof obj === 'object' && !Array.isArray(obj)) {
      Object.keys(obj).forEach(item => {
        newOBJ[obj[item].toLowerCase().replaceAll(' ', '')] = { key: obj[item], value: item }
      })
    } else if (typeof obj === 'object' && Array.isArray(obj)) {
      // let newArr = Utils.sortAlphabetically(obj)
      obj.forEach(item => {
        newOBJ[item.full_name.toLowerCase().replaceAll(' ', '')] = { key: item.full_name, value: item.id }
      })
    }
    return Utils.sortObjectAlphabetically(newOBJ)
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        {props.label &&
          <InputLabel id="controlled-open-select-label">{props.label}</InputLabel>
        }
        <Select
          labelId="controlled-open-select-label"
          id="controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={key}
          onChange={handleChange}
        >
          {props.lookup ?
            Object.values(handleSortedObj(props.lookup)).map(item => {
              return <MenuItem value={item.value}>{item.key}</MenuItem>
            })
            :
            <MenuItem value="">
            </MenuItem>
          }
        </Select>
      </FormControl>
    </div>
  );
}
