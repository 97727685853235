import React from 'react'
import { DialogTitle, DialogContent, DialogActions, Button, Paper, Grid } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import ViewTable from '../../Tables/ViewTable'

const useStyles = makeStyles({
	root: {
		justifyContent: 'space-between'
	}
})

function ViewSubscriptions({
  columns=[],
  subscriptions=[],
  setDialog,
  handleSubmit,
  onClose,
	client,
	isLoading
}) {

	const classes = useStyles()

	return (
		<>
			<DialogContent style={{marginTop: '16px'}}>
				<Paper>
					<ViewTable isLoading={isLoading} title={`Preview New Subscriptions for ${client}`} columns={columns} data={subscriptions} exportButton={false} pageSize={50}/>
				</Paper>
			</DialogContent>
			<DialogActions classes={{
				root: classes.root
			}}>
				<Grid item>
					<Button onClick={onClose}>Cancel</Button>
				</Grid>
				<Grid item>
					<Button onClick={() => setDialog('add')}>Back</Button>
					<Button size='medium' color='primary' variant='contained' onClick={handleSubmit}>Confirm</Button>
				</Grid>
			</DialogActions>
		</>
	)
}

export default ViewSubscriptions