import React, {useState, useEffect} from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, Button, DialogActions, TextField, Switch, FormControlLabel, IconButton, Tooltip, Paper, ExpansionPanel, ExpansionPanelActions, ExpansionPanelDetails, ExpansionPanelSummary, Typography} from "@material-ui/core"
import { Info, ExpandMore, Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import ControlledOpenSelectManagedState from '../../DropdownSelect/ControlledOpenSelectManagedState'
import ControlledMultiSelect from '../../DropdownSelect/ControlledMultiSelect'
import ControlledOpenSelect from '../../DropdownSelect/ControlledOpenSelect'
import Utils from '../../../Utils/Utils'
import EditableTable from '../../Tables/EditableTable'
import LinearWithValueLabel from '../../LinearProgressWithLabel/LinearProgressWithLabel'

const useStyles = makeStyles({
	root: {
		marginBottom: '16px',
		width: '100%'
	}
})

function AddSubscriptions({
	onClose,
	handleSubmit,
	validation, 
	handleEvent,
	clients = [], 
	keys = [], 
	services = [], 
	statuses = [], 
	providers = [],
	coverageData = [],
	apps = [],
	setCurrentClient,
	currentClient='',
	subscriptions=[]
}) {

	const classes = useStyles()

	const [newItem, setNewItem] = useState({
		client: '',
		apikey: '',
		service: '',
		provider: '',
		status: '',
		max_distance: '',
		use_external_api: '',
		external_api_details: '{}',
		external_api_timeout: ''
	})
	
	const [selectedStatuses, setSelectedStatuses] = useState([])
	const [selectedProviders, setSelectedProviders] = useState([])

	const [expanded, setExpanded] = useState(true)
	
	// only filter once a client is selected
	const [filteredKeys, setFilteredKeys] = useState(null)

	// only filter once a service is selected
	const [filteredProviders, setFilteredProviders] = useState(null)

	const [rowData, setRowData] = useState(subscriptions)

	const columns = [
		{
			title: 'Client',
			field: 'client',
			required: true,
			lookup: Utils.sortAlphabetically(clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {})),
			editable: 'never',
		},
		{
			title: 'API Key',
			field: 'apikey',
			required: true,
			lookup: Utils.sortAlphabetically(keys.reduce((acc, cur, i) => { acc[cur.id] = cur.description; return acc; }, {})),
			editComponent: props => (
				<ControlledOpenSelect
					onChange={(e) => {
						props.onChange(e)
					}}
					value={props.value}
					lookup={filteredKeys ? Utils.sortAlphabetically(filteredKeys) : Utils.sortAlphabetically(keys.reduce((acc, cur, i) => { acc[cur.id] = cur.description; return acc; }, {}))}
				/>
			),
		},
		{
			title: 'Service',
			field: 'service',
			required: true,
			lookup: Utils.sortAlphabetically(services.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {})),
			editComponent: props => (
				<ControlledOpenSelect
					onChange={(e) => {
						props.onChange(e)
					}}
					value={props.value}
					lookup={Utils.sortAlphabetically(services.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))}
				/>
			),
		},
		{
			title: 'Provider',
			field: 'provider',
			required: true,
			lookup: Utils.sortAlphabetically(providers.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {})),
			editComponent: props => {
				const rowService = props?.rowData?.service || null

				return (
					<ControlledOpenSelect
						onChange={e => props.onChange(e)}
						value={props.value}
						lookup={Utils.sortAlphabetically(handleFilteringProvidersInColumns(rowService))}
					/>
				)
			},
		},
		{
			title: 'Status',
			field: 'status',
			required: true,
			lookup: Utils.sortAlphabetically(statuses.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))
		},
		{ title: 'Max Distance', field: 'max_distance', type: 'numeric' },
		{ title: 'Use External API', field: 'use_external_api', type: 'boolean' },
		{ title: 'External API Timeout (ms)', field: 'external_api_timeout', type: 'numeric' },
		{ 
			title: 'External API Details', 
			field: 'external_api_details', 
			cellStyle: {
				minWidth: '400px'
			},
			initialEditValue: '{}',
			editComponent: props => (
				<TextField
					id="external_api_details"
					fullWidth
					multiline
					rows={10}
					variant="outlined"
					value={props.value}
					onChange={e => props.onChange(e.target.value)}
				/>
			)
		}
	]

	useEffect(() => {
	  if(currentClient){
			handleFilteringKeys(currentClient)
			setNewItem({
				...newItem,
				client: currentClient,
			})
		}
	}, [])

	const handleFilteringKeys = (client) => {

		let filteredKeysArray

		if (keys) {
			let tmp = keys.filter(item => { return item.client === (+client) });
			filteredKeysArray = tmp.reduce((acc, cur, i) => {
				if (cur.description) acc[cur.id] = cur.description
				else {
					let tmp = apps.filter(item => { return item.id === (+cur.app) });
					acc[cur.id] = cur.key + `(${tmp[0].full_name})`;
				}
				return acc;
			}, {});
		}

		setFilteredKeys(filteredKeysArray)
	}

	const handleFilteringProvidersInColumns = (service) => {
		const filteredProviders = {}

		if (providers && coverageData) {
			let tmp = coverageData.filter(item => `${item.service}` === service).map((item) => item.provider)

			providers.forEach((item) => {

				if (tmp.includes(item.id)) {
					filteredProviders[item.id] = item.full_name
				}
			})
		}    

		return filteredProviders
	}

	const handleFilteringProviders = (service) => {
		const filteredProviders = handleFilteringProvidersInColumns(service)
		
		setFilteredProviders(filteredProviders)
	}

	const updateNewItem = (e, field) => {

		const updatedItem = {...newItem}
		updatedItem[field] = e

		setNewItem(updatedItem)
	}

	const handleClearItem = () => {
		setNewItem({
			client: currentClient,
			apikey: '',
			service: '',
			provider: '',
			status: '',
			max_distance: '',
			use_external_api: '',
			external_api_details: '',
			external_api_timeout: ''
		})

		setSelectedStatuses([])
		setSelectedProviders([])
		setFilteredProviders(null)
	}

	const handleAddToList = async () => {

		if (Utils.isEmpty(newItem)) {
			if (handleEvent) { 
				handleEvent("Invalid: All fields are empty.", "error")
			}
			return
		}
		
		if(selectedProviders.length <= 0 ) {
			if(handleEvent) {
				handleEvent('Invalid property: Provider', "error")
			}
			return
		}
		
		if(selectedStatuses.length <= 0 ) {
			if(handleEvent) {
				handleEvent('Invalid property: Status', "error")
			}
			return
		}        
		const newRowData = [...rowData]
	
		try {

			const compareStringArray = newRowData.map((row) => `${row.client}${row.service}${row.provider}${row.status}`)

			const promises = selectedProviders.flatMap((provider) => {
				return selectedStatuses.map((status) => {
					const newSubscription = { ...newItem, status, provider }

					const compareString = `${newSubscription.client}${newSubscription.service}${newSubscription.provider}${newSubscription.status}` 

					if(compareStringArray.includes(compareString)) {
						return Promise.reject('Invalid Properties: Duplicate Subscription');
					}
			
					if(validation) {
						return validation(newSubscription)
					}
 
					return Promise.resolve();	
				})
			})

			const results = await Promise.allSettled(promises)

			// if every promise is rejected
			if(results.every(result => result.status === 'rejected')) {
				throw new Error(results?.[0]?.reason || results[0])
			}

			results.forEach(result => {
				if(result.status === 'fulfilled') {
					newRowData.push(result.value)
				}
			})
            
			setRowData(newRowData)

			// scroll to table after submitting
			const newSubscriptionsList = document.getElementById('new-subscriptions-table')

			newSubscriptionsList.scrollIntoView()
            
			if(handleEvent) {
				handleEvent("Successfully added", "success")
			}

		} catch (err) {
			if(handleEvent) {
				handleEvent(err.message || err, "error")
			}
		}
	}

	const onSubmit = () => {
		if(!rowData.length) {
			if(handleEvent) {
				handleEvent('Please add a Subscription', 'warn')
			}

			return
		}

		const editing = rowData.some((row) => {
			if(row?.tableData?.editing) {
				return true
			}
		})

		if(editing) {
			if(handleEvent) {
				handleEvent('Please finish editing', 'warn')
			}

			return
		}
		
		if(handleSubmit) handleSubmit(rowData)
	} 

	const onRowDelete = (e) => { 
		return new Promise((resolve, reject) => {
	
			const newData = [...rowData]
			const index = e.tableData.id
	
			if(index > -1) { 
				newData.splice(index, 1)
				setRowData(newData)

				if(handleEvent) {
					handleEvent("Removed successfully", "success")
				}
			}		
			resolve()
		})
	}

	const onRowUpdate = ({tableData: oldDataTableData, ...oldData}, newData) => {
		if(JSON.stringify(oldData) === JSON.stringify(newData)) return Promise.resolve(newData)

		const newRowData = [...rowData]

		const compareStringArray = newRowData.map((row) => `${row.client}${row.service}${row.provider}${row.status}`)

		const newDataCompareString = `${newData.client}${newData.service}${newData.provider}${newData.status}` 
		const oldDataCompareString = `${oldData.client}${oldData.service}${oldData.provider}${oldData.status}` 

		if(newDataCompareString !== oldDataCompareString && (compareStringArray.includes(newDataCompareString))) {
			return Promise.reject("Invalid Properties: Duplicate Subscription")
		}

		return new Promise((resolve, reject) => {

			const rowIndex = oldDataTableData.id
			newRowData[rowIndex] = newData

			setRowData(newRowData)
			handleEvent('Updated successfully','success')
			
			resolve()
		})
	}

	return (
		<>
			<DialogTitle id="simple-dialog-title" alignContent='center'>
				Bulk Upload New Subscriptions
			</DialogTitle>
			<DialogContent>
				<ExpansionPanel 
					classes={{
						root: classes.root
					}}
					expanded={expanded}
					id='subscription-details-panel'
					onChange={() => setExpanded(!expanded)}
				>
					<ExpansionPanelSummary expandIcon={<ExpandMore />}>
						<Typography>Add Subscriptions</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails >
						<Grid container direction='column'>
							<Grid container direction='row'>
								<Grid item xs={6}>
									<ControlledOpenSelectManagedState
										onChange={e => {
											if (currentClient !== e) {
												setCurrentClient(e);
												setRowData([]);
												setFilteredProviders(null)
												setSelectedProviders([])
												setSelectedStatuses([])
											} else {
												setCurrentClient(e);
											}
											handleFilteringKeys(e)
											updateNewItem(e, 'client')
										}}
										disabled={currentClient !== -1 && currentClient ? true : false}
										lookup={Utils.sortAlphabetically(clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))}
										label='Client'
										value={newItem.client || currentClient || ''}
									/>
								</Grid>
								<Grid item xs={6}>
									<ControlledOpenSelectManagedState
										onChange={e => updateNewItem(e, 'apikey')}
										lookup={filteredKeys ? Utils.sortAlphabetically(filteredKeys) : Utils.sortAlphabetically(keys.reduce((acc, cur, i) => { acc[cur.id] = cur.description; return acc; }, {}))}
										label='API Key'
										value={newItem.apikey}
									/>
								</Grid>
							</Grid>
							<Grid container direction='row'>
								<Grid item xs={4}>
									<ControlledOpenSelectManagedState
										onChange={e => {
											handleFilteringProviders(e)
											updateNewItem(e, 'service')
											setSelectedProviders([])
										}}
										lookup={Utils.sortAlphabetically(services.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))}
										label='Service'
										value={newItem.service}
									/>
								</Grid>
								<Grid item xs={4}>
									<ControlledMultiSelect
										onChange={e => {
											setSelectedProviders(e)
										}}
										lookup={filteredProviders ? Utils.sortAlphabetically(filteredProviders) : Utils.sortAlphabetically(providers.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))}
										label='Provider'
										values={selectedProviders}
									/>
								</Grid>
								<Grid item xs={4}>
									<ControlledMultiSelect
										onChange={e => {
											setSelectedStatuses(e)
										}}
										lookup={Utils.sortAlphabetically(statuses.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {}))}
										label='Status'
										values={selectedStatuses}
									/>
								</Grid>
							</Grid>
							<Grid container direction='row'>
								<Grid item xs={4}>
									<TextField  
										label="Max Distance" 
										size='small' 
										fullWidth
										value={newItem.max_distance}
										onChange={(event) => {
											updateNewItem(event.target.value, 'max_distance')
										}}
										style={{margin: '8px', width: '90%'}}
									/>
								</Grid>
								<Grid item xs={4} container style={{justifyContent: 'center'}}>
									<FormControlLabel
										control={
											<Switch 
												onChange={(event) => {
													updateNewItem(event.target.checked, 'use_external_api')
												}}
												checked={newItem.use_external_api === true}
												color='primary'
											/>
										} 
										label="Use External API"
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField 
										label="External API Timeout (ms)"
										size='small' 
										value={newItem.external_api_timeout}
										onChange={(event) => {
											updateNewItem(event.target.value, 'external_api_timeout')
										}}   
										style={{margin: '8px', width: '90%'}}    
									/>
								</Grid>
							</Grid>
							<Grid container>
								<TextField
									id="external_api_details"
									fullWidth
									multiline
									rows={5}
									style={{margin: '8px'}}
									variant="outlined"
									label='External API Details'
									value={newItem.external_api_details}
									onChange={event => {
										updateNewItem(event.target.value, 'external_api_details')
									}}
								/>
							</Grid>
							<Grid container style={{justifyContent:'flex-end'}}>
								<Button size='medium' variant='standard' onClick={handleClearItem}>
									Clear
								</Button>
								<Button size='medium'color='primary' variant='contained' onClick={handleAddToList}>
									Add
								</Button>
							</Grid>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<Paper id='new-subscriptions-table'>
					<EditableTable 
						title={'New Subscriptions'} 
						columns={columns} 
						data={rowData} 
						options={{
							pageSize: 50,
							exportButton: false
						}}
						isDeletable={true}
						isEditable={true}
						onlyEditing={true}
						handleRowRemove={onRowDelete}
						handleRowUpdate={onRowUpdate}
						validation={validation}
						handleError={(error) => {
							handleEvent(error,'error')
						}}
					/>
				</Paper>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button size='medium' color='primary' variant='contained' onClick={onSubmit}>Next</Button>
			</DialogActions>
		</>
	)
}

export default AddSubscriptions