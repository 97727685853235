import React, {useState, useEffect} from 'react'

import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, makeStyles} from '@material-ui/core'
import Utils from '../../Utils/Utils';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  },
}));

function ControlledMultiSelect({...props}) {
  const classes = useStyles();
  const [values, setValues] = useState(props.values ? props.values : '');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(props.hasOwnProperty('values')) {
      setValues(props.values)
    }
  }, [props.values])

  const handleChange = event => {
    setValues(event.target.value);
    if (props.onChange) props.onChange(event.target.value)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSortedObj = (obj) => {
    let newOBJ = {}
    if (typeof obj === 'object' && !Array.isArray(obj)) {
      Object.keys(obj).forEach(item => {
        newOBJ[obj[item].toLowerCase().replaceAll(' ', '')] = { key: obj[item], value: item }
      })
    } else if (typeof obj === 'object' && Array.isArray(obj)) {
      // let newArr = Utils.sortAlphabetically(obj)
      obj.forEach(item => {
        newOBJ[item.full_name.toLowerCase().replaceAll(' ', '')] = { key: item.full_name, value: item.id }
      })
    }
    return Utils.sortObjectAlphabetically(newOBJ)
  }

  const renderValue = (selected) => {

    if(props.lookup) {
      const displayArray = Object.values(handleSortedObj(props.lookup)).map((item) => {
        if(values.includes(item.value)) return item.key

        return null
      })

      return displayArray.filter(value => value !== null && value !== undefined && value !== '').join(',')
    } else {
      return selected.join(',')
    }
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        {props.label &&
          <InputLabel id="controlled-open-select-label">{props.label}</InputLabel>
        }
        <Select
          labelId="controlled-open-select-label"
          id="controlled-open-select"
          open={open}
          onClose={handleClose}
          multiple
          onOpen={handleOpen}
          value={values}
          onChange={handleChange}
          endAdornment={props.endAdornment && props.endAdornment}
          inputProps={props.inputProps && props.inputProps}
          renderValue={props.renderValue && props.renderValue || renderValue}
        >
          {props.lookup ?
            Object.values(handleSortedObj(props.lookup)).map(item => {
              return (
                <MenuItem value={item.value}>
                  <Checkbox color='primary' checked={values.includes(item.value)} />
                  <ListItemText primary={item.key} />
                </MenuItem>
              )
            })
            :
            <MenuItem value="">
            </MenuItem>
          }
        </Select>
      </FormControl>
    </div>
  );
}

export default ControlledMultiSelect